/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Ajax) => {
        const MODE_FILTER = 0
        const MODE_LOAD_MORE = 1

        const filters = document.getElementById('filters')
        const container = document.querySelector('.news-container')
        let xhr

        const getLoaded = () => container.querySelectorAll('article').length
        const getTotal = () => container.querySelector('[data-total]').dataset.total

        filters.addEventListener('submit', e => {
            e.preventDefault()

            if(xhr) xhr.abort()

            const data = new FormData(filters)
            let mode = e.submitter && e.submitter.closest('.load-more') ? MODE_LOAD_MORE : MODE_FILTER
            let loadingArea = container

            if(mode === MODE_LOAD_MORE) {
                data.append('offset', getLoaded())
                
                loadingArea = e.submitter.closest('.load-more')
            } else {
                app.updateURL(data)
            }

            xhr = new Ajax({
                url: filters.action,
                method: filters.method,
                data: data,
                onLoadStart: () => loadingArea.dataset.loading = true,
                onLoadEnd: () => loadingArea.dataset.loading = false,
                onSuccess: response => {
                    if(mode === MODE_LOAD_MORE) {
                        // Append loaded posts
                        container.querySelector('ul').innerHTML += response

                        // Remove load more
                        if(getLoaded() >= getTotal()) loadingArea.remove()
                    } else {
                        // Replace loaded posts
                        container.innerHTML = response
                    }
                    
                    // Init lazy loader for newly added images 
                    container.querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))
                }
            })
        })

        filters.addEventListener('change', () => filters.dispatchEvent(new Event('submit', { cancelable: true })))
    }
}
