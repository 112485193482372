/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        const container = $('.list-container')
        const ajaxRoute = '/ajax/actualites'

        let xhr;

        // Filtering
        $(document).on('click', '[data-category]:not(.active)', (e) => {
            $('[data-category].active').removeClass('active')

            $(e.target).closest('[data-category]').addClass('active')

            sendAjaxRequest('filter')
        })

        // Load more
        $(document).on('click', '.load-more', (e) => {
            sendAjaxRequest('load-more')
        })

        // Ajax request
        function sendAjaxRequest(type) {
            if (xhr) xhr.abort()

            const category = $('[data-category].active').data('category') ? $('[data-category].active').data('category') : false

            let data = category ? `categorie=${category}` : ''

            if (type == 'load-more') {
                data += data ? '&' : ''
                data += `offset=${$('[data-total]').children().length}`
            }

            data += '&ajax-type=' + type

            xhr = $.ajax({
                url: ajaxRoute,
                method: 'GET',
                data: data,
                dataType: 'json',
                beforeSend: () => {
                    if (type == 'load-more') {
                        $('.load-more').addClass('loading')
                    } else {
                        container.addClass('loading')
                    }
                },
                complete: () => {
                    if (type == 'load-more') {
                        $('.load-more').removeClass('loading')
                    } else {
                        container.removeClass('loading')
                    }
                },
                success: (response) => {
                    if (type == 'load-more') {
                        const list = $('[data-total]')
                        list.append(response)

                        const items = list.children()

                        if (items.length >= list.data('total')) {
                            $('.load-more').remove()
                        }
                    } else {
                        container.html(response)
                    }
                }
            })
        }
    }
}
