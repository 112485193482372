/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';
import gsap from 'gsap';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

/*
|
| Importing Components
|-----------------------
*/
import MaterializeForm from '@components/materialize-form';
import Kira from '@components/kira';
import Menu from '@components/menu';
import Overlay from '@components/overlays';
import Plyr from '@components/plyr.min.js';

/*
|
| Importing Utils
|-------------------
*/
import * as app from '@utils/global';
import Router from '@utils/router';
import Ajax from '@utils/ajax';

/*
|
| Importing App files
|----------------------
*/
import main from '@pages/main';
import news from '@pages/news';
import animations from '@pages/animations';
import actualites from '@pages/actualites.js';
import contact from '@pages/contact';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        file: animations,
        dependencies: [app, gsap, Menu, Kira]
    },
    {
        file: main,
        dependencies: [app, Overlay, Swiper, Plyr],
    },
    {
        file: news,
        dependencies: [app, Ajax],
        resolve: '.news-container'
    },
    {
        file: actualites,
        dependencies: [app],
        resolve: '#page-actualites-archive'
    },
    {
        file: contact,
        dependencies: [app, MaterializeForm],
        resolve: '#page-contact'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

