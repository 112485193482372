/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Overlay, Swiper, Plyr) => {

		/*
		|
		| Slider partenaires
		|-----------------
		*/
		$(".swiper-partenaires").each(function (i) {
			var swiperpartenaires = new Swiper($(this)[0], {
				spaceBetween: 0,
				speed: 1500,
				loop: true,
				slidesPerView: 5.5,
				centeredSlides: true,
				autoplay: {
					delais: 1500
				},
				breakpoints: {
					0: {
						slidesPerView: 2.5,
						centeredSlides: true
					},
					768: {
						slidesPerView: 2.5,
						centeredSlides: true
					},
					992: {
						slidesPerView: 3.5,
						centeredSlides: false
					},
					1200: {
						slidesPerView: 5.5,
						centeredSlides: false
					}
				}
			});
		});

		/*
		|
		| Slider galerie single news
		|---------------------------
		*/
		$(".swiper-galerie").each(function (i) {
			var swipergalerie = new Swiper($(this)[0], {
				spaceBetween: 30,
				speed: 1500,
				loop: true,
				slidesPerView: 1.5,
				centeredSlides: true,
				autoplay: {
					delais: 1500
				},
				breakpoints: {
					0: {
						slidesPerView: 1.1
					},
					768: {
						slidesPerView: 1.5
					}
				}
			});
		});

		/*	
		|
		| Plyr
		|-----------------
		*/
		const $customPlyrVideo = jQuery('.custom-plyr-video');
		$.each($customPlyrVideo, function () {
			const $video = $(this);
			const $videoPoster = $video.find('.item-poster');
			const $videoIcon = $video.find('.item-play-icon');
			const videoId = $video.data('id');
			const type = $video.data('type');
			let player = new Plyr(`#${videoId}`);
			$(`#${videoId}`).addClass('custom-plyr-video');

			player.on("play", (event) => {
				let delayInMilliseconds = 500;
				setTimeout(function () {
					jQuery('.item-poster').css('opacity', '0');
				}, delayInMilliseconds);
			});
		});


		/*
		|
		| Scroll menu fixed
		|-----------------
		*/
		var prevScrollpos = window.pageYOffset;
		window.onscroll = function () {
			var currentScrollpos = window.pageYOffset;
			if (prevScrollpos > currentScrollpos) {
				document.getElementById("header").style.top = "0";
			} else {
				document.getElementById("header").style.top = "-120px";
			}
			if (currentScrollpos > 50) {
				jQuery("header").removeClass('top');
			} else {
				jQuery("header").addClass('top');
			}

			prevScrollpos = currentScrollpos;

			if (currentScrollpos < 30) {
				document.getElementById("header").style.top = "0";
			}
		}

		/*-------- Lazy loading --------*/
		document.querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))

		/*-------- Modals --------*/
		document.querySelectorAll('.modal').forEach((item) => {
			app.overlays[item.id] = new Overlay(item)
		})
	}
}
